<template>
  <div class="unemployment">
    <div class="totalCount">共有&nbsp;<span style="color:rgb(75,139,239)">{{totalCount}}</span>&nbsp;条数据</div>
    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <van-cell v-for="item in dataList" :key="item.id" @click="getInfo(item.userId, item.community, item.compareTime, item.membership, item.moveOut)"  class="margin-border">
          <div class="item">
            <van-cell-group :border="false">
              <a :href="'tel:'+item.mobile" @click.stop=""><img src="@/assets/img/phone.png" alt="" class="tel"/></a>
              <van-cell class="itemCont" :title="item.address" :label="item.visitDes" is-link center>
                <template #icon>
                  <div style="display:flex;flex-direction:column;justify-content:center;align-items:center">
                    <img :src="item.sex==1?require('@/assets/img/headImgMan.png'):require('@/assets/img/headImgWoman.png')" alt="" class="widthImg">
                    <div style="margin-top:10px">{{item.name}}</div>
                  </div>
                </template>
                <template #title>
                  <div class="custom-title contTitle">{{item.sexStr}}/{{item.age}}岁</div>
                  <div class="custom-title contTitle">{{desensitization(item.idNumber)}}</div>
                  <div class="custom-title contTitle">{{item.regAddress}}</div>
                </template>
              </van-cell>
              <van-cell class="title" center>
                <template #title>
                  <template>
                    <div v-if="item.moveOut==0" style="color:rgb(58,129,237)">未迁出</div>
                    <div v-if="item.moveOut==1" style="color:rgb(255,184,92)">已迁出</div>
                    <div v-if="item.moveOut==2" style="color:rgb(58,129,237)">确认已迁出</div>
                  </template>

                </template>
                <van-button plain type="info" v-if="item.moveOut==1" @click.stop="moveOut(item.id)">确认已更新</van-button>
              </van-cell>
            </van-cell-group>
          </div>
        </van-cell>
      </van-list>
      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
    </div>
  </div>
</template>

<script>
import {getImageStream} from '@/utils/index'
import { mapMutations } from 'vuex'
import { desensitizationIdNumber } from '@/utils/utils'
export default {
  data() {
    return {
      loading: false,
      finished: false,
      page: 0,
      limit: 10,
      totalCount: 0,
      dataList: []
    };
  },
  methods: {
    desensitization (e) {
      return desensitizationIdNumber(e)
    },
    ...mapMutations,
    // imgUrl (img) {
    //   if (img&&img.length!==0) {
    //     return getImageStream(img[0].filePath)
    //   } else {
    //     return require('@/assets/img/flower.png')
    //   }
    // },
    moveOut (id) {
      this.$dialog.confirm({
        message: '确认'
      }).then( ()=> {
        this.$http({
          url: this.$http.adornUrl('/wxapp/app/social/domicile/update'),
          method: 'post',
          data: this.$http.adornData({
            moveOut: 2,
            id: id
          })
        }).then(({data})=> {
          console.log(data)
          if (data.code == 0) {
            this.$toast.success('操作成功')
            this.page = 0
            this.finished = false
            this.dataList = []
            this.getDataList()
          } else{
            this.$toast.fail(data.msg)
          }
          this.$toast.clear()
        },err=> {this.$toast.fail(err)})
      })
    },
    getInfo (id, community, compareTime, membership, moveOut) {
      this.$router.push({path: '/regAddressInfo', query: {userId: id, community, compareTime, membership, moveOut}})
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/app/social/domicile/list'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          page: this.page,
          limit: this.limit
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    }
  },
  mounted () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
  }
}
</script>

<style>
.van-grid-item__content {
  padding: 10px  0;
}
.van-search__content {
  border-radius: 30px;
}
</style>
<style lang="scss" scoped>
.tel {
  position:absolute;
  right: 80px;
  top: 20px;
  z-index:999;
  width:50px;
  height:50px;
}
.margin-border {
  padding: 0 !important;
}
.itemCont {
  .van-cell__label {
    width: 420px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .van-icon__image {
    margin-right: 20px;
  }
}
.van-tag {
  text-align: center;
  display: block;
  width: 190px;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  padding: 0 10px;
}
.contTitle {
  margin-left: 30px;
  color: #666;
  font-size: 24px;
  width: 420px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  span {
    color: #3D7EFE;
    font-size: 30px;
  }
}
.widthImg {
  border-radius: 50%;
  width: 90px;
  height: 90px;
}
.title {
  .van-cell__title {
    color: #999;
  }
  .van-cell__value {
    color: #FE5E5E;
    & > .van-button {
      height: 60px;
    }
    & > .van-button--normal {
      padding: 0 20px;
    }
  }
}
.unemployment {
  min-height: 100vh;
  background-color: #f5f5f5;
  .totalCount {
    color: rgb(47, 47, 47);
    padding: 0 40px;
    background-color: transparent;
  }
  //padding-top: 100px;
  .cont {
    padding: 0 20px;
    background-color: #f5f5f5;
    .van-list > .van-cell {
      margin-bottom: 20px;
      border-radius: 10px;
      padding-top: 0;
      padding-bottom: 0;
      .title {
        margin: 0;
      }
    }
    ul {
      width: 100%;
      height: 100%;
      li {
        background-color: #fff;
        border-radius: 10px;
        margin-top: 20px;
      }
    }
  }
}
.van-icon-ellipsis {
  line-height: 100px;
  vertical-align: middle;
  margin-left: 5px;
}
</style>
